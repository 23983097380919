@font-face {
  font-family: 'NoeseIcons';
  src:  url('fonts/NoeseIcons.eot?3n82xk');
  src:  url('fonts/NoeseIcons.eot?3n82xk#iefix') format('embedded-opentype'),
    url('fonts/NoeseIcons.ttf?3n82xk') format('truetype'),
    url('fonts/NoeseIcons.woff?3n82xk') format('woff'),
    url('fonts/NoeseIcons.svg?3n82xk#NoeseIcons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="noese-"], [class*=" noese-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'NoeseIcons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.noese-stop:before {
  content: "\e948";
}
.noese-text-long:before {
  content: "\e939";
}
.noese-ray-end:before {
  content: "\e93a";
}
.noese-format-letter-case-upper:before {
  content: "\e93b";
}
.noese-rocket-launch:before {
  content: "\e93c";
}
.noese-alert-circle:before {
  content: "\e93d";
}
.noese-link-variant-off:before {
  content: "\e93e";
}
.noese-sign-direction-remove:before {
  content: "\e93f";
}
.noese-sleep:before {
  content: "\e940";
}
.noese-translate:before {
  content: "\e941";
}
.noese-arrow-decision:before {
  content: "\e942";
}
.noese-layers-triple:before {
  content: "\e943";
}
.noese-cloud-check-variant:before {
  content: "\e944";
}
.noese-cloud-arrow-up-outline:before {
  content: "\e945";
}
.noese-cloud-arrow-up:before {
  content: "\e946";
}
.noese-history:before {
  content: "\e947";
}
.noese-eye:before {
  content: "\e938";
}
.noese-close-circle-outline:before {
  content: "\e937";
}
.noese-chat-plus-outline:before {
  content: "\e936";
}
.noese-trash-can-outline:before {
  content: "\e911";
}
.noese-drag-horizontal-variant-2:before {
  content: "\e932";
}
.noese-open-in-new:before {
  content: "\e933";
}
.noese-subdirectory-arrow-left:before {
  content: "\e934";
}
.noese-chat-processing-outline:before {
  content: "\e935";
}
.noese-layers-triple-outline:before {
  content: "\e931";
}
.noese-play-circle-outline:before {
  content: "\e930";
}
.noese-robot-outline:before {
  content: "\e92f";
}
.noese-power:before {
  content: "\e92e";
}
.noese-tree-circle:before {
  content: "\e92c";
}
.noese-tree-chevron:before {
  content: "\e92d";
}
.noese-step-message:before {
  content: "\e923";
}
.noese-step-choice:before {
  content: "\e924";
}
.noese-step-url:before {
  content: "\e925";
}
.noese-step-goto:before {
  content: "\e926";
}
.noese-step-email:before {
  content: "\e927";
}
.noese-step-phone:before {
  content: "\e928";
}
.noese-trash:before {
  content: "\e929";
}
.noese-bookmark-empty:before {
  content: "\e92a";
}
.noese-bookmark-full-check:before {
  content: "\e92b";
}
.noese-pencil:before {
  content: "\e922";
}
.noese-checkbox-blank-circle:before {
  content: "\e921";
}
.noese-chevron-right-circle:before {
  content: "\e920";
}
.noese-radiobox-blank:before {
  content: "\e91d";
}
.noese-radiobox-marked:before {
  content: "\e91e";
}
.noese-message-2:before {
  content: "\e91f";
}
.noese-translate-circle:before {
  content: "\e91c";
}
.noese-loading:before {
  content: "\e91b";
}
.noese-bubble-dots:before {
  content: "\e91a";
}
.noese-emoji:before {
  content: "\e919";
}
.noese-bulb-on:before {
  content: "\e918";
}
.noese-attention-circle:before {
  content: "\e917";
}
.noese-bubble-plus:before {
  content: "\e913";
}
.noese-item-plus:before {
  content: "\e914";
}
.noese-list-plus:before {
  content: "\e915";
}
.noese-play-circle:before {
  content: "\e916";
}
.noese-star:before {
  content: "\e912";
}
.noese-bookmark:before {
  content: "\e900";
}
.noese-carousel:before {
  content: "\e906";
}
.noese-code-circle:before {
  content: "\e907";
}
.noese-envelope-circle:before {
  content: "\e908";
}
.noese-forward-circle:before {
  content: "\e909";
}
.noese-images:before {
  content: "\e90a";
}
.noese-link-circle:before {
  content: "\e90b";
}
.noese-magic-wand-circle:before {
  content: "\e90c";
}
.noese-phone-circle:before {
  content: "\e90d";
}
.noese-pin-circle:before {
  content: "\e90e";
}
.noese-text-bubble:before {
  content: "\e90f";
}
.noese-text-bubbles:before {
  content: "\e910";
}
.noese-plus-circle:before {
  content: "\e905";
}
.noese-chevron-up:before {
  content: "\e904";
}
.noese-cross:before {
  content: "\e903";
}
.noese-newtork:before {
  content: "\e902";
}
.noese-chevron-down:before {
  content: "\e901";
}
